<template>
  <div class="GradeList" ref="list">
    <div class="block"></div>
    <div class="Choice" v-if="ChoiceData.length>=1&&ChoiceView">
      <div class="data" v-for="(item,index) in ChoiceData" :key="index">
        <div v-if="item.style=='0'" @click="openData(item)">
          <div class="title">{{ item.GotoTime }}</div>
          <div class="number">{{ item.Number }}</div>
          <div class="arrow"></div>
        </div>
        <div v-else @click="openDatas(item)">
          <div class="title">{{ item.StartTime }}-{{ item.EndTime }}</div>
          <div class="number">{{ item.Number }}</div>
          <div class="arrow"></div>
        </div>
      </div>
      <div class="data"></div>
    </div>
    <div class="DataHeader" v-if="DataView">
      <img class="arrow" src="../assets/image/exhibit/arrows.png" @click="CloseData"/>
      <div class="number">目前数量:<span>{{ TolGradeNumber }}</span></div>
      <div class="TolGradeTitle">{{ TolGradeTitle }}</div>
    </div>
    <div class="Data" v-if="DataView">
      <div class="block" v-for="(item ,index) in Data" :key="index">
        <div class="img"
             @click="openImg()">
          <img/>
        </div>
        <div class="img"
             @click="openImg(item.SidNumber)">
          <img
              :src="item.imageUrl"></div>
        <div class="number">编号：{{ item.SidNumber }}</div>
        <div class="user">作者：{{ item.penNames }}</div>
        <div class="title">标题：{{ item.names }}</div>
        <div class="time">时间：{{ item.times }}</div>
        <div class="great" v-bind:class="{greats: item.Collects}"><i
            @click="work(item._id,item.user_id)"></i><span>{{ item.collect }}</span></div>
        <!--        <div class="step" v-bind:class="{steps: item.CollectNots}"><i-->
        <!--            @click="worknot(item._id,item.user_id)"></i><span>不喜欢</span>-->
        <!--        </div>-->
      </div>
    </div>
    <Details ref="Details"></Details>
    <Load ref="Load"></Load>
  </div>
</template>

<script>
import {GotoTimeinit, GotoTimeFind, findCollect, setkorknot, setkork, GotoTimeFindUsed} from '../api/exhibit'
import Details from "./Details";
import {Toast} from "vant";
import Load from "./load";

export default {
  data() {
    return {
      ChoiceView: true,
      ChoiceData: [],
      DataView: false,
      TolGradeTitle: '',
      TolGradeNumber: 0,
      Data: [],
      Height: 0,
    }
  },
  mounted() {
    let list = this.$refs.list
    let height = list.style.height
    this.Height = 'height:' + height + 'px'
  },
  components: {
    Details,
    Load
  },
  methods: {
    openDatas(data) {
      let EndTime = Number(data.EndTime.split('级')[0]);
      let StartTime = Number(data.StartTime.split('级')[0]);
      let number = StartTime - EndTime
      let addData = []
      for (let i = 0; i <= number; i++) {
        addData.push(StartTime - i)
      }
      GotoTimeFindUsed({addData}).then(res => {
        let data = res.data
        if (data.length == 0) {
          Toast('暂无数据')
        } else {
          this.ChoiceView = false
          this.DataView = true
          this.TolGradeTitle = EndTime + '级-' + StartTime+'级'
          this.TolGradeNumber=data.length
          this.UpCollect(data)
        }
      })

    },
    // 打开详情(海报)
    openImg(SidNumber) {
      this.$refs.Details.openImg(SidNumber)
    },
    //查询点赞与点踩记录并更新视图
    UpCollect(data) {
      this.$refs.Load.open()
      let userInfo = this.$store.state.userInfo
      let _id = userInfo._id
      if (_id) {
        findCollect({_id}).then(res => {
          this.$refs.Load.close()
          let Collect = res.Collect
          let CollectNot = res.CollectNot
          for (let item in data) {
            let name = data[item].name
            let penName = data[item].penName
            if (name.length >= 5) {
              data[item].names = name.slice(0, 5) + '...'
            } else {
              data[item].names = name
            }
            if (penName.length >= 5) {
              data[item].penNames = penName.slice(0, 5) + '...'
            } else {
              data[item].penNames = penName
            }

            for (let i in Collect) {
              if (Collect[i].SidNumber == data[item].SidNumber) {
                data[item].Collects = true
                break;
              }
            }
            for (let i in CollectNot) {
              if (CollectNot[i].SidNumber == data[item].SidNumber) {
                data[item].CollectNots = true
                break;
              }
            }
          }
          this.Data = data
        })
      } else {
        this.Data = data
      }
    },
    //点踩
    worknot(work_id, user_ids) {
      let userInfo = this.$store.state.userInfo
      let user_id = userInfo._id
      let name = userInfo.Name

      if (user_id) {
        setkorknot({work_id, user_id, user_ids, name}).then(res => {
          if (res.code == 0) {
            Toast.success(res.message);
            this.uppopularCol()
          } else {
            Toast.fail(res.message);
          }
        })
      } else {
        Toast('请登录账号才能进行操作');
      }
    },
    uppopularCol() {
      this.getData()
    },
    //点赞
    work(work_id, user_ids) {
      let userInfo = this.$store.state.userInfo
      let user_id = userInfo._id
      let name = userInfo.Name

      if (user_id) {
        setkork({work_id, user_id, user_ids, name}).then(res => {
          if (res.code == 0) {
            Toast.success(res.message);
            this.uppopularCol()
          } else {
            Toast.fail(res.message);
          }
        })
      } else {
        Toast('请登录账号才能进行操作');
      }
    },

    UpCollects(data) {
      let userInfo = this.$store.state.userInfo
      let _id = userInfo._id
      findCollect({_id}).then(res => {
        let Collect = res.Collect
        let CollectNot = res.CollectNot
        for (let item in data) {
          for (let i in Collect) {
            let SidNumber = data[item].SidNumber
            if (item == 0) {
              data[item].Coll = 'one'
            } else if (item == 1) {
              data[item].Coll = 'two'
            } else if (item == 2) {
              data[item].Coll = 'three'
            } else {
              data[item].Coll = 'T'
            }

            if (SidNumber == Collect[i].SidNumber) {
              if (item == 0) {
                data[item].Coll = 'oneT'
              } else if (item == 1) {
                data[item].Coll = 'twoT'
              } else if (item == 2) {
                data[item].Coll = 'threeT'
              } else {
                data[item].Coll = 'Ts'
              }
              break;
            }
          }
          for (let i in CollectNot) {
            let SidNumber = data[item].SidNumber
            if (SidNumber == CollectNot[i].SidNumber) {
              data[item].Colls = 1
              break;
            } else {
              data[item].Colls = 0
              break;
            }
          }
        }
        this.Data = data
      })
    },
    // 打开详情数据
    openData(data) {
      this.ChoiceView = false
      this.DataView = true
      this.TolGradeTitle = data.GotoTime
      this.TolGradeNumber = data.Number
      this.GotoTime = data.GotoTime
      this.getData()
    },
    // 返回选择年级
    CloseData() {
      this.ChoiceView = true
      this.DataView = false
    },
    //根据年级获取数据
    getData() {
      let GotoTime = this.GotoTime
      GotoTimeFind({GotoTime}).then(res => {
        let sum = res.sum
        this.UpCollect(sum)
      })
    },
    init(titleNumber) {
      this.titleNumber = titleNumber
      GotoTimeinit().then(res => {
        let TolGotoTime = res.TolGotoTime
        this.ChoiceData = TolGotoTime
      })
    }
  }
}
</script>

<style scoped lang="scss">
.GradeList {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;

  .block {
    width: 100%;
    height: 5px;
  }

  .Choice {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    overflow-y: auto;

    .data {
      width: 48%;
      height: 100px;
      border: 2px solid #f0f0f0;
      position: relative;

      .title {
        position: absolute;
        left: 20px;
        top: 20px;
        font-weight: bold;
      }

      .number {
        position: absolute;
        left: 20px;
        top: 55px;
        width: 80px;
        text-align: center;
        border: 1px solid #ab6365;
        border-radius: 7px;
      }

      .arrow {
        background-image: url("../assets/image/exhibit/arrow.png");
        background-size: 100% 100%;
        width: 18px;
        height: 18px;
        position: absolute;
        right: 10px;
        top: 20px;
      }
    }

    .datas {
      width: 48%;
      height: 100px;
    }
  }

  .DataHeader {
    width: 100%;
    height: 40px;
    position: relative;

    .arrow {
      position: absolute;
      width: 20px;
      height: 20px;
      left: 15px;
      top: 10px;
    }

    .number {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;

      span {
        font-weight: bold;
      }

    }

    .TolGradeTitle {
      position: absolute;
      right: 20px;
      top: 10px;
      font-size: 15px;
    }
  }

  .Data {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .block {
      width: 100%;
      height: 100px;
      background-color: #ffffff;
      position: relative;
      margin-bottom: 10px;
      @mixin pos {
        position: absolute;
        font-size: 11px;
      }

      .img {
        position: absolute;
        width: 60px;
        height: 85px;
        left: 30px;
        top: 6px;
        overflow: hidden;
        border: 1px solid #f0f0f0;

        img {
          //transform: rotate(90deg);
          width: 100%;
          height: 100%;
        }
      }

      .number {
        @include pos;
        left: 100px;
        top: 10px;
      }

      .title {
        @include pos;
        left: 100px;
        top: 50px;
      }

      .time {
        @include pos;
        left: 100px;
        top: 70px;
      }

      .user {
        @include pos;
        left: 100px;
        top: 30px;
        width: 120px;
      }

      .great {
        @include pos;
        right: 97px;
        top: 10px;

        i {
          margin-right: 10px;
          display: inline-block;
          background-image: url("../assets/image/create/like.png");
          width: 17px;
          height: 17px;
          background-size: 100% 100%;
        }
      }

      .greats {
        i {
          background-image: url("../assets/image/create/likes.png");
        }
      }

      .step {
        @include pos;
        right: 67px;
        top: 40px;


        i {
          margin-right: 10px;
          display: inline-block;
          background-image: url("../assets/image/create/step.png");
          width: 17px;
          height: 17px;
          background-size: 100% 100%;
          float: left;

        }

        span {
          float: left;
          display: inline-block;
        }
      }

      .steps {
        i {
          float: left;
          background-image: url("../assets/image/create/steps.png");
        }
      }
    }
  }
}
</style>
