<template>
  <div class="Details" v-if="Details" :style="Control">
    <div class="close" @click="closeImg"></div>
    <div class="openCom" @click="openCom" v-if="!commentStart"></div>
    <div class="openImg" @click="openImgs" v-if="commentStart"></div>
    <div class="main" id="posterHtml" ref="imageTest">
      <div class="qrcodeImg" ref="qrcodeImg" v-if="!bgSum"></div>
      <canvas ref="canvas" class="canvas"></canvas>
      <img :src="bgSum" class="bgSum">
      <div class="filoink" @click="openfiloink" v-if="evidenceId"></div>
      <div class="comment" v-if="commentStart">
        <div class="title"><i></i>全部评论</div>
        <div class="data">
          <div class="block" v-for="(item,index) in contentData" :key="index">
            <div class="basic">
              <div class="avatar"><img :src="item.imgUrl"></div>
              <div class="PenName">{{ item.name }}</div>
              <div class="Time">{{ item.time }}</div>
            </div>
            <div class="textBlock">
              <div class="text">{{ item.content }}</div>
            </div>
          </div>
        </div>
        <div class="release">
          <input type="text" placeholder="快来发布一篇评论吧～" @keydown.enter="release" v-model="content">
          <div class="send" @click="release">发表</div>
        </div>
      </div>
    </div>
    <div class="createGoTo" @click="createGoTo" v-if="createGoToView">
      <span>我也要画</span>
      <img class="left" src="../assets/image/exhibit/left.png"/>
      <img class="right" src="../assets/image/exhibit/left.png"/>
      <img class="feather" src="../assets/image/exhibit/featherWhite.png"/>
    </div>
    <div class="create" v-if="!bgSum"></div>
    <img id="createSum" class="bgsimg" v-if="posterImg" :src="posterImg"/>
    <Load ref="Load"></Load>
  </div>
</template>

<script>
import Load from "./load";
import {
  base64Img,
  base64QR,
  popularAdd,
  chainDetailUrl,
  addEvaluate,
  findEvaluate,
  findSidNumber
} from "../api/exhibit";
import {titless} from "../utils/poster";
import {Toast} from "vant";

export default {
  data() {
    return {
      Control: '',
      Details: false,
      commentStart: false,
      createGoToView: false,
      img: '',
      name: '',
      time: '',
      penName: '',
      el: '',
      ctx: '',
      width: 1000,
      height: 2000,
      posterImg: '',
      bgurl: '',
      titles: '',
      bgSum: '',
      SidNumber: '',
      bottom: '',
      qrcode: '',
      OKCin: '',
      evidenceId: '',
      content: '',
      contentData: []
    }
  },
  mounted() {
    // createGoToView
    let path = this.$route.path
    if (path == '/zju/lookwork') {
      this.createGoToView = true
    } else {
      this.createGoToView = false
    }
  },
  components: {
    Load
  },
  methods: {
    //字体加载测试初始化
    initViews() {
      this.Details = true
      setTimeout(() => {
        setTimeout(() => {
          this.Details = false
        }, 50)
      }, 10)
    },
    createGoTo() {
      let userInfo = this.$store.state.userInfo
      let user_id = userInfo._id
      if (user_id) {
        this.$router.push('/zju/introduce')
      } else {
        this.$router.push('/')
      }
    },
    openCom() {
      this.contentData = []
      this.commentStart = true
      this.content = ''
      this.findrelease()
    },
    openImgs() {
      this.contentData = []
      this.commentStart = false
      this.content = ''
    },
    findrelease() {
      this.$refs.Load.open()
      let sidNumber = this.SidNumber
      findEvaluate({sidNumber}).then(res => {
        this.$refs.Load.close()
        let data = res.data
        for (let i in data) {
          let name = data[i].name
          if (name.length >= 5) {
            data[i].name = name.slice(0, 5) + '...'
          } else {
            data[i].name = name
          }
        }
        this.contentData = data
      })
    },
    release() {
      let userInfo = this.$store.state.userInfo
      let userId = userInfo._id
      let sidNumber = this.SidNumber
      let content = this.content
      if (content) {
        this.$refs.Load.open()
        addEvaluate({userId, sidNumber, content}).then(res => {
          Toast(res.message)
          this.content = ''
          this.$refs.Load.close()
          this.findrelease()
        })
      } else {
        Toast('评论内容未空')
      }

    },
    openfiloink() {
      let evidenceId = this.evidenceId
      chainDetailUrl({evidenceId}).then(res => {
        let url = res.url
        window.location.href = url
      })
    },
    down() {
      let posterImg = this.posterImg
      let a = document.createElement("a"); // 生成一个a元素
      let event = new MouseEvent("click"); // 创建一个单击事件
      a.download = name || "photo.png"; // 设置图片名称
      a.href = posterImg; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    },
    openImg(SidNumber) {
      this.commentStart = false
      findSidNumber({SidNumber}).then(res => {
        let data = res.sum
        if (this.$route.path == '/zju/lookwork') {
          this.createGoToView = true
        }


        let id = data.id
        let imageUrl = data.imageUrl
        let collect = data.collect
        let time = data.time
        let penName = data.penName
        let titleNumber = data.titleNumber
        let evidenceId = data.evidenceId
        let name = data.name
        this.number = Number(id)

        let terminal = navigator.userAgent
        this.evidenceId = evidenceId
        popularAdd({SidNumber, terminal, titleNumber})

        this.Details = true
        setTimeout(() => {
          this.$refs.Load.open()
        }, 10)

        let p1 = new Promise((resolve) => {
          let OKCin = 'https://hz-mayi.nos-eastchina1.126.net/2021-12-4/OKCin.png'
          base64Img({url: OKCin}).then(res => {
            this.OKCin = res
            resolve('加载完成')
          })
        })

        let p2 = new Promise((resolve) => {
          base64Img({url: imageUrl}).then(res => {
            this.img = res
            resolve('加载完成')
          })
        })

        let p3 = new Promise((resolve) => {
          let bcurl = 'https://hz-mayi.nos-eastchina1.126.net/2021-11-1/2021-10-11_opbgs.jpg'
          base64Img({url: bcurl}).then(res => {
            this.bgurl = res
            resolve('加载完成')
          })
        })

        let p4 = new Promise((resolve) => {
          let newFont = new FontFace('Vonwaon', 'url(https://hz-mayi.nos-eastchina1.126.net/2022-4-30/Vonwaon.ttf)');
          newFont.load().then(function (font) {
            document.fonts.add(font);
            resolve('加载完成')
          });
        })

        Promise.all([p1, p2, p3, p4]).then(() => {

          this.SidNumber = SidNumber
          this.name = name
          this.collect = collect
          this.time = time
          this.penName = penName

          let url = 'http://wrth.3qlab.com/zju/lookwork?SidNumber=' + SidNumber
          this.createQrcode(url)
        }).catch((error) => {
          console.log(error)
        })
      })


    },
    initcanvas() {
      //生成初始化
      let canvas = this.$refs.canvas
      //定义长高
      canvas.width = this.width
      canvas.height = this.height
      // 转换2d对象
      let crx = canvas.getContext('2d')
      //填充背景白色颜色
      crx.fillStyle = '#fff'
      crx.fillRect(0, 0, this.width, this.height);
      //倍率设置
      // crx.translate(3, 3);
      //红框（开发定位，生产勿用）
      crx.lineWidth = 1;
      crx.strokeStyle = "#973733";
      crx.strokeRect(75, 330, 850, 1300);

      //渲染头部header
      let p1 = new Promise((resolve) => {
        let titles = new Image()
        titles.src = titless()
        titles.onload = (() => {
          crx.drawImage(titles, 0, 20, 1000, 240);
          resolve('success')
        })
      })

      //渲染作品
      let p2 = new Promise((resolve) => {
        let img = new Image()
        img.src = this.img
        img.onload = (() => {
          crx.drawImage(img, 75, 330, 850, 1300);
          resolve('success')
        })
      })


      // //渲染底部
      // let p3 = new Promise((resolve) => {
      //   let bottom = new Image()
      //   bottom.src = bottomss()
      //   bottom.onload = (() => {
      //     crx.drawImage(bottom, 0, 1850, 1000, 150);
      //     resolve('success')
      //   })
      // })


      //渲染二维码
      let p4 = new Promise((resolve) => {
        let qrcodeImg = this.$refs.qrcodeImg.querySelector('img').src
        let qrcode = new Image()
        qrcode.src = qrcodeImg
        qrcode.onload = (() => {
          crx.drawImage(qrcode, 640, 1660, 310, 300);
          resolve('success')
        })
      })

      //字体标题渲染
      let p6 = new Promise((resolve) => {
        crx.fillStyle = '#000';
        crx.font = "normal normal 300 40px Calibri";

        let name = this.name
        let penName = this.penName
        if (name.length >= 10) {
          name = name.slice(0, 10) + '...'
        }
        if (penName.length >= 10) {
          penName = penName.slice(0, 10) + '...'
        }

        crx.fillText('坐标：' + this.SidNumber, 75, 1725);
        crx.fillText('标题：' + penName, 75, 1790);
        crx.fillText('作者：' + name, 75, 1855);
        crx.fillText(this.time, 75, 1915);

        setTimeout(() => {
          crx.font = "normal normal 300 80px Vonwaon";
          crx.fillStyle = '#2551c1';
          // 我是第xxx位

          crx.fillText('祝浙大125岁生日快乐', 90, 275);
          crx.fillText('我是第', 150, 150);
          crx.fillText('位', 720, 150);

          let numberSum = this.getNunber(this.number)
          crx.fillStyle = '#e31925';
          crx.fillText(numberSum, 420, 150);
          // e31925
          resolve('success')
        }, 1300)
      })

      //渲染盖章
      let p5 = new Promise((resolve) => {
        let evidenceId = this.evidenceId
        if (evidenceId) {
          setTimeout(() => {
            let OKCin = new Image()
            OKCin.src = this.OKCin
            OKCin.onload = (() => {
              // crx.rotate(-Math.PI / 4);
              // crx.drawImage(OKCin, 190, 985, 80, 80);
              crx.rotate(35 * Math.PI / 180);
              crx.drawImage(OKCin, 1280, 1050, 180, 158)
              crx.rotate(0);
              resolve('success')
            })
          }, 1000)
        } else {
          resolve('success')
        }
      })


      Promise.all([p1, p2, p4, p6, p5]).then(() => {
        const imgBase64 = canvas.toDataURL("image/png")
        this.bgSum = imgBase64
        this.$refs.Load.close()
        Toast('请长按图片进行保存');
      }).catch((error) => {
        console.log(error)
      })
    },
    createQrcode(text) {
      const qrcodeImgEl = this.$refs.qrcodeImg
      qrcodeImgEl.innerHTML = "";

      base64QR({url: text}).then(res => {
        let img = `<img src="${res}"/>`
        qrcodeImgEl.innerHTML = img;
      })

      setTimeout(() => {
        this.initcanvas()
      }, 400)
    },
    closeImg() {
      this.img = ''
      this.name = ''
      this.time = ''
      this.penName = ''
      this.bgSum = ''
      setTimeout(() => {
        this.Details = false
      }, 10)
    },
    getNunber(str) {
      if (str < 10) {
        return '0000' + str
      } else if (str < 100) {
        return '000' + str
      } else if (str < 1000) {
        return '00' + str
      } else if (str < 10000) {
        return '0' + str
      }
    },


  }
}
</script>

<style scoped lang="scss">
.Details {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  //background-color: #020202;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;


  .create {
    width: 280px;
    height: 560px;
    background-color: #FFFFFF;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -140px;
    margin-top: -280px;
  }

  .bgsimg {
    width: 280px;
    height: 560px;
    background-color: #973733;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -100px;
    margin-top: -280px;
  }

  .close {
    width: 24px;
    height: 24px;
    background-image: url("../assets/image/map/close.png");
    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: 143px;
    margin-top: -280px;
    toggle-group: 0;
    background-color: #FFFFFF;
  }

  .openCom {
    width: 24px;
    height: 24px;
    background-image: url("../assets/image/exhibit/openCom.png");
    background-size: 15px 15px;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: 143px;
    margin-top: 252px;
    toggle-group: 0;
    background-color: #FFFFFF;
    border-radius: 15px;
  }

  .openImg {
    width: 24px;
    height: 24px;
    background-image: url("../assets/image/exhibit/openImg.png");
    background-size: 15px 15px;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: 143px;
    margin-top: 252px;
    toggle-group: 0;
    background-color: #FFFFFF;
    border-radius: 15px;
  }

  .main {
    width: 280px;
    height: 560px;
    background-color: #FFFFFF;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -140px;
    margin-top: -280px;


    .bgSum {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .comment {
      width: 280px;
      height: 560px;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #FFFFFF;

      .title {
        width: 100%;
        height: 30px;
        line-height: 30px;
        font-size: 13px;
        border-bottom: #f0f0f0 1px solid;

        i {
          background-repeat: no-repeat;
          background-image: url("../assets/image/exhibit/comment.png");
          background-size: 15px 15px;
          background-position: center center;
          width: 20px;
          height: 30px;
          display: inline-block;
          float: left;
          margin-right: 10px;

        }

      }

      .data {
        width: 100%;
        height: 491px;
        overflow-y: scroll;
        @mixin text {
          color: #666;
          font-size: 11px;
          position: absolute;
        }

        .block {
          width: 100%;
          height: auto;
          background-color: #FFFFFF;
          .basic {
            width: 100%;
            height: 50px;
            position: relative;

            .avatar {
              width: 30px;
              height: 30px;
              border-radius: 50px;
              background-color: #973733;
              position: absolute;
              left: 10px;
              top: 10px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 15px;
              }
            }

            .PenName {
              @include text;
              left: 50px;
              top: 16px;
            }

            .Time {
              @include text;
              right: 5px;
              top: 16px;
              position: absolute;
              font-size: 11px;
            }
          }
          .textBlock{
            width: 100%;
            height: available;
            overflow: hidden;
            .text {
              width: 230px;
              //height:-webkit-fill-available;
              height:available;
              margin-top: 5px;
              font-size: 12px;
              float: right;
              color: #666666;
              line-height: 25px;
            }
          }


        }
      }

      .data::-webkit-scrollbar {
        display: none;
      }

      .release {
        position: absolute;
        width: 100%;
        height: 40px;
        bottom: 0;
        left: 0;

        input {
          width: 190px;
          height: 20px;
          background-color: #f0f0f0;
          position: absolute;
          left: 10px;
          top: 8px;
          border: none;
          font-size: 12px;
          text-indent: 0.5rem;
          color: #666666;
        }

        .send {
          width: 50px;
          height: 22px;
          line-height: 22px;
          font-size: 12px;
          right: 15px;
          top: 8px;
          background-color: #973733;
          color: #FFFFFF;
          text-align: center;
          position: absolute;
        }
      }
    }

    .titleNav {
      width: 210px;
      height: 30px;
      position: absolute;
      left: 50%;
      margin-left: -105px;
      top: 13px;
      background-image: url("../assets/image/exhibit/titles.png");
      background-size: 100% 100%;
    }

    .qrcodeImg {
      position: absolute;
      width: 75px;
      height: 75px;
      top: 0;
      left: 0;
      opacity: 0;
    }


    .down {
      width: 24px;
      height: 24px;
      background-image: url("../assets/image/map/down.png");
      background-size: 100% 100%;
      position: absolute;
      left: 50%;
      margin-left: 13px;
      bottom: -40px;
      background-color: #FFFFFF;
      border-radius: 15px;
    }


    .img {
      width: 230px;
      height: 330px;
      position: absolute;
      left: 35px;
      top: 60px;
      border: 1px solid #973733;

      img {
        width: 100%;
        height: 100%;

      }
    }

    @mixin pos {
      position: absolute;
      font-size: 12px;
    }

    .time {
      position: absolute;
      left: 40px;
      bottom: 50px;
      font-size: 12px;
    }

    .great {
      @include pos;
      left: 40px;
      bottom: 70px;
      //width: 100px;
      height: 20px;

      i {
        margin-right: 10px;
        display: inline-block;
        background-image: url("../assets/image/create/like.png");
        width: 15px;
        height: 15px;
        background-size: 100% 100%;
      }
    }

    .step {
      @include pos;
      left: 40px;
      bottom: 94px;
      //width: 100px;
      height: 20px;

      i {
        margin-right: 10px;
        display: inline-block;
        background-image: url("../assets/image/create/step.png");
        width: 15px;
        height: 15px;
        background-size: 100% 100%;
      }
    }

    .bottom {
      width: 100%;
      height: 30px;
      position: absolute;
      bottom: 0;
      left: 0;
      overflow: hidden;

      p {
        position: absolute;
        color: #FFFFFF;
        left: 0;
        top: -12px;
        text-align: center;
        font-size: 13px;
        width: 100%;
        height: 30px;
        line-height: 30px;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
      }

      //opacity: 0;
    }


  }


  .filoink {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 145px;
    top: 460px;
    z-index: 12;
  }

  .canvas {
    position: fixed;
    top: 0;
    opacity: 0;

    //left: 50%;
    //top: 50%;
    //margin-left: -150px;
    //margin-top: -289.5px;
    z-index: -40;
  }

  .createGoTo {
    width: 280px;
    height: 35px;
    background-color: #CC2C2C;
    position: absolute;
    left: 50%;
    margin-left: -140px;
    top: 50%;
    margin-top: 281px;

    span {
      font-size: 13px;
      color: #FFFFFF;
      letter-spacing: 5px;
      position: absolute;
      left: 120px;
      top: 6px;
    }

    .feather {
      position: absolute;
      left: 87px;
      top: 8px;
      width: 20px;
      height: 20px;
    }

    .left {
      width: 40px;
      position: absolute;
      left: 19px;
      top: 8px;
    }

    .right {
      width: 40px;
      position: absolute;
      right: 23px;
      top: 8px;
      transform: rotate(180deg);
    }
  }
}
</style>
