<template>
  <div class="home">
    <Home_Bird ref="Home_Bird" v-if="Bird"></Home_Bird>
    <Home_Lamp ref="Home_Lamp" v-if="Lamp"></Home_Lamp>
    <Home_Login ref="Home_Login" v-if="Login"></Home_Login>
  </div>

</template>
<script>
import Home_Bird from '../components/Home_Bird'
import Home_Lamp from '../components/Home_Lamp'
import Home_Login from '../components/Home_Login'

export default {
  data() {
    return {
      noSwiper: false,
      page: 'right: 50px!important; width: 50px;left:unset',
      Bird: true,
      Lamp: false,
      Login: false,
    }
  },
  methods: {
    upperLogin() {
      this.Lamp = false
      this.Login = true
     setTimeout(()=>{
       this.$refs.Home_Login.init()
     },100)
    },
    upperBird() {
      this.Bird = true
      this.Lamp = false
    },
    nextLamp() {
      this.Bird = false
      this.Lamp = true
    },
    init() {
      this.$refs.Home_Lamp.initUp()
    },
    close() {
      this.$refs.Home_Lamp.NoinitUp()
    }
  },
  components: {
    Home_Bird,
    Home_Lamp,
    Home_Login,
  }

}
</script>

<style lang="scss">
.home {
  width: 100%;
  height: 100%;

  .next {
    position: absolute;
    right: 30px;
    bottom: 50px;
    color: #B6FFF3;
    width: 80px;
    height: 30px;
    line-height: 30px;
    border: 2px solid #B6FFF3;
    border-radius: 5px;
    text-align: center;
    z-index: 500;
  }
}

</style>




