<template>
  <div>
    <!--    v-if="!viewMap"-->
    <div class="openmap" @click="openmap" v-if="!viewMap">认领坐标</div>

    <div class="load" v-if="load">
      <div class="ico"></div>
    </div>

    <div class="viewMap" v-if="viewMap">
      <div class="map">
        <Map ref="map"></Map>
      </div>

      <div class="choose">
        <div class="block">
          <div class="set" @click="openFixed" v-bind:class="{ red: mapStart }">指定坐标</div>
          <div class="set" @click="openRandom" v-bind:class="{ red: !mapStart }">随机坐标</div>
        </div>
        <div class="refresh" v-if="!mapStart" @click="Randomref"></div>
        <div class="reset" v-if="mapStart" @click="reset"></div>
      </div>
      <div class="enlarge">

      </div>
      <div class="mapinfo">
        <div class="block" @click="openFixedX">
          <span>X</span>
          <input :disabled="true" placeholder="请选择X坐标" v-model="mapNum.x"/>
        </div>
        <div class="block" v-if="mapNum.x||mapNum.x=='0'" @click="openFixedY">
          <span>Y</span>
          <input :disabled="true" placeholder="请选择Y坐标" v-model="mapNum.y"/>
        </div>
      </div>
      <div class="ok" @click="getCreate">开始创作</div>
    </div>

    <div class="PickerX" v-if="pickerX">
      <van-picker
          class="main"
          title="请选择X坐标"
          show-toolbar
          :columns="columnsX"
          @confirm="onConfirmX"
          @cancel="closecancelX"
      />
    </div>
    <div class="PickerX" v-if="pickerY">
      <van-picker
          class="main"
          title="请选择Y坐标"
          show-toolbar
          :columns="columnsY"
          @confirm="onConfirmY"
          @cancel="closecancelY"
      />
    </div>
  </div>
</template>

<script>
// InspectWork
import {claim, fixed, InitWorkFind, DeleteWork, InspectWork, NotSelecta, WorkNumbers} from '../api/introduce'
import {Dialog, Notify, Picker} from "vant";
import Map from "../components/Map";


export default {
  data() {
    return {
      load: false,
      viewMap: true,
      mapStart: true,
      data: '',
      pickerX: false,
      pickerY: false,
      columnsX: [],
      columnsY: [],

      creationSizeX: '',
      creationSizeY: '',
      canvasNumberX: '',
      canvasNumberY: '',


      titleNumber: '',
      mapNum: {
        x: null,
        y: null,
      },
      list: [],
      finishList: []
    }
  },
  mounted() {
    this.init()
  },
  components: {
    [Picker.name]: Picker,
    Map
  },
  methods: {
    openmap() {
      this.load = true
      this.viewMap = true
      this.init()
    },
    initColumnsX(str) {
      this.columnsX = str
    },
    reset() {
      this.mapNum.x = null
      this.mapNum.y = null
      this.$refs.map.clear()
    },
    closecancelX() {
      this.pickerX = false
    },
    closecancelY() {
      this.pickerY = false
    },
    openFixedY() {
      if (this.mapStart == false) {
        return
      }
      this.pickerY = true
    },
    initcolumnsY() {
      this.load = false
      let storagedata = this.$refs.map.storagedata()

      let finishList = storagedata.finishList
      let number = storagedata.canvasNumberY
      let x = this.mapNum.x


      let Sigsum = []
      let YData = []
      for (let k = 0; k < finishList.length; k++) {
        if (finishList[k].x == x) {
          YData.push({x: finishList[k].x, y: finishList[k].y})
        }
      }
      for (let i = 1; i <= number; i++) {
        if (YData.length == 0) {
          Sigsum.push(i + '(可用)')
        } else {
          for (let l = 0; l < YData.length; l++) {
            if (i == YData[l].y) {
              Sigsum.push(i + '(不可用)')
            } else {
              Sigsum.push(i + '(可用)')
            }
          }
        }
      }

      NotSelecta({x}).then(res=>{
        let data=res.data
        for (let i in Sigsum){
          let newStr = i.replace(/\(.*?\)/g, '');
          newStr = Number(newStr)
          for (let k in data){
            if (data[k].y==newStr){
              Sigsum[i]=newStr+'(不可用)'
              break;
            }
          }
        }
      })
      this.columnsY = Sigsum
    },
    openFixedX() {
      if (this.mapStart == false) {
        return
      }
      this.pickerX = true
    },
    onConfirmX(value) {
      this.pickerX = false
      this.$refs.map.clear()
      let str = value;
      let newStr = str.replace(/\(.*?\)/g, '');
      newStr = Number(newStr)
      this.mapNum.x = newStr
      this.mapNum.y = null
      this.initcolumnsY()
    },
    onConfirmY(value) {
      let regex = /\((.+?)\)/g;
      let options = value.match(regex)
      if (options[0] == '(可用)') {
        this.pickerY = false
        let str = value;
        let newStr = str.replace(/\(.*?\)/g, '');
        newStr = Number(newStr)
        this.mapNum.y = newStr
        this.$refs.map.highlights(this.mapNum)
      } else {
        Dialog.alert({
          message: '很抱歉，当前画布已认领',
        }).then(() => {
          // on close
        });
      }

    },
    init() {
      this.load = true
      let userInfo = this.$store.state.userInfo
      let user_id = userInfo._id
      fixed().then(res => {
        let num = res.num
        let finishList = num.finishList
        this.titleNumber = num.titleNumber
        //行数数量
        this.canvasNumberX = num.canvasNumberX
        //列数数量
        this.canvasNumberY = num.canvasNumberY
        this.creationSizeX = num.creationSizeX
        this.creationSizeY = num.creationSizeY

        this.finishList = finishList
        this.$refs.map.init(num, finishList)
        InitWorkFind({user_id}).then(res => {
          this.load = false
          if (res.code == 1) {
            Dialog.confirm({
              message: res.message,
              confirmButtonText: '确认',
              cancelButtonText: '放弃'
            }).then(() => {
              let data = res.data
              let SidNumber = data.SidNumber
              let idNumber = data.idNumber
              let x = data.x
              let y = data.y
              let sinx = data.sinx
              let siny = data.siny

              WorkNumbers({user_id}).then(res => {
                if (res.code == 0) {
                  this.$router.push({
                    path: '/zju/create',
                    query: {
                      SidNumber,
                      idNumber,
                      x,
                      y,
                      sinx,
                      siny
                    }
                  })
                }else {
                  Notify({type: 'warning', message: res.message});
                }
              })
            }).catch(() => {
              DeleteWork({user_id}).then(res => {
                if (res.code == 0) {
                  Notify({type: 'success', message: res.message});
                } else {
                  Notify({type: 'warning', message: res.message});
                }

              })
            })
          }
        })
      })
    },
    Randomref() {
      this.load = true
      claim().then(res => {
        this.$refs.map.clear()
        this.load = false
        let num = res.num
        this.$refs.map.highlights(num)
        this.mapNum = num
      })
    },
    openRandom() {
      if (this.mapStart == true) {
        this.mapStart = false
        this.load = true
        claim().then(res => {
          this.load = false
          let num = res.num
          this.$refs.map.highlights(num)
          this.mapNum = num
        })
      }
    },
    openFixed() {
      if (this.mapStart == false) {
        this.$refs.map.clear()
        this.mapStart = true
        this.mapNum.x = null
        this.mapNum.y = null
      }
    },
    getCreate() {
      Dialog.confirm({
        message: '是否确认申领该作品？',
      }).then(() => {
        //自定义画布尺寸 x
        let xTin = this.creationSizeX
        //自定义画布尺寸 y
        let yTin = this.creationSizeY

        let x = this.mapNum.x
        let y = this.mapNum.y

        if (x && y) {
          //编号
          let titleNumber = this.titleNumber
          let SidNumber = titleNumber + 'X' + x + 'Y' + y;

          let xs = x * xTin;
          let ys = y * yTin

          let userInfo = this.$store.state.userInfo
          let user_id = userInfo._id


          WorkNumbers({user_id}).then(res => {
            if (res.code == 0) {
              InspectWork({SidNumber, user_id, x, y, sinx: xs, siny: ys}).then(res => {
                if (res.code == 0) {
                  Notify({type: 'success', message: res.message});
                  this.$router.push({
                    path: '/zju/create',
                    query: {
                      SidNumber,
                    }
                  })
                } else {
                  Dialog.alert({
                    message: res.message
                  }).then(() => {
                    // on close
                  });
                }
              })
            } else {
              Notify({type: 'warning', message: res.message});
            }
          })
        } else {
          Dialog.alert({
            message: '请选择当前的画布',
          }).then(() => {
            // on close
          });
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">


.PickerX {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;

  .main {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}

.enars {
  width: 90%;
  height: 30px;
  line-height: 30px;
  margin: 0 auto;
  margin-top: 10px;

  .enlarge {
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
    background-image: url("../assets/image/map/enlarge.png");
    display: inline-block;
  }

  .narrow {
    background-size: 100% 2px;
    background-position: center center;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    background-image: url("../assets/image/map/narrow.png");
    display: inline-block;
    margin-left: 15px;
  }

  span {
    font-size: 14px;
    margin-left: 20px;
  }

  .data {
    font-size: 14px;
    display: inline;
    margin-left: 15px;

  }
}


.openmap {
  background-color: #973733;
  color: white;
  text-align: center;
  height: 40px;
  line-height: 40px;
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
}

.load {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;

  .ico {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -15px;
    margin-top: -15px;
    background-size: 100% 100%;
    background-image: url("../assets/image/map/load.png");
    transition: 0.5s;
    animation: rotate 1s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}

.map {
  width: 100%;
  height: 250px;
  overflow: hidden;
  white-space: nowrap;
  position: relative;

  //background-color: rgba(114, 6, 13, 0.81);
}

.choose {
  width: 100%;
  height: 50px;
  margin-top: 10px;

  .block {
    width: 180px;
    height: 35px;
    box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
    margin-left: 20px;
    display: flex;
    float: left;


    .set {
      width: 50%;
      height: 100%;
      text-align: center;
      font-size: 14px;
      line-height: 35px;
      cursor: pointer;
      user-select: none;
    }

    .i {
      //border-right: 1px solid #000;
    }

    .red {
      background-color: #973733;
      color: #ffffff;
    }
  }

  .refresh {
    width: 35px;
    height: 35px;
    background-size: 25px 25px;
    background-image: url("../assets/image/map/refresh.png");
    background-position: center center;
    background-repeat: no-repeat;
    margin-left: 20px;
    //display: inline-block;
    float: left;
    cursor: pointer;
    user-select: none;
  }


  .reset {
    width: 35px;
    height: 35px;
    background-size: 25px 25px;
    background-image: url("../assets/image/map/reset.png");
    background-position: center center;
    background-repeat: no-repeat;
    margin-left: 20px;
    //display: inline-block;
    float: left;
    cursor: pointer;
    user-select: none;
  }
}

.mapinfo {
  width: 100%;
  height: 35px;
  text-indent: 20px;
  margin-top: 10px;

  .block {
    display: inline-block;
    width: 150px;
    height: 35px;
    box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
    margin-right: 20px;
    text-indent: 0;
    position: relative;

    span {
      width: 35px;
      height: 35px;
      display: inline-block;
      //color: rgba(159, 159, 159, 0.5);
      text-align: center;
      line-height: 35px;
      font-size: 14px;
      position: absolute;
      left: 0;
    }

    input {
      outline: none;
      text-indent: 1rem;
      width: 114px;
      height: 100%;
      font-size: 14px;
      color: #666;
      border: none;
      position: absolute;
      left: 35px;
      padding: 0;
      background-color: #fff;
    }
  }
}

.ok {
  width: 367px;
  height: 44px;
  background-color: #973733;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  line-height: 44px;
  margin: 0 auto;
  margin-top: 20px;
  cursor: pointer;
  user-select: none;
}
</style>
