import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }


const routes = [
  {
    path: '/',
    component: () => import('../views/home'),
    meta: {
      title: "万人添画",
    },
  },
  {
    path: '/zju/login',
    component: () => import('../views/login'),
    meta: {
      title: "万人添画",
    },
  },
  {
    path: '/zju/introduce',
    component: () => import('../views/introduce'),
    meta: {
      title: "万人添画 ",
    },
  },
  {
    path: '/zju/law',
    component: () => import('../views/law'),
    meta: {
      title: "法律条款",
    },
  },
  {
    path: '/zju/map',
    component: () => import('../views/map'),
    meta: {
      title: "选择坐标",
    },
  },
  {
    path: '/zju/user',
    component: () => import('../views/user'),
    meta: {
      title: "个人中心",
    },
  },
  {
    path: '/zju/create',
    component: () => import('../views/create'),
    meta: {
      title: "绘画板",
    },
  },
  {
    path: '/zju/exhibit',
    component: () => import('../views/exhibit'),
    meta: {
      title: "万人添画",
    },
  }, {
    path: '/zju/lookwork',
    component: () => import('../views/lookwork'),
    meta: {
      title: "展示作品",
    },
  },
  {
    path: '/zju/createPc',
    component: () => import('../views/createPc'),
    meta: {
      title: "绘画板",
    },
  },
  {
    path: '/zju/Stars',
    component: () => import('../components/Stars'),
    meta: {
      title: "测试",
    },
  },
  {
    path: '/zju/lamp',
    component: () => import('../views/lamp'),
    meta: {
      title: "绘画结果",
    },
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
