<template>
  <div class="introduce">
    <div class="data">

      <p>2022年5月21日，浙江大学将迎来125周岁生日，3Q Lab希望与浙大师生校友一起，将对母校的祝福寄于画笔，共同创作全球首幅万人共创的NFT画作——<span class="red">“灿若星辰”NFT</span>，献礼浙江大学校庆。
      </p>
      <span class="bule">规则说明：</span><br>
      <p>此次万人添画@浙江大学<span class="red">【灿若星辰NFT】</span>活动，将一幅画作分割为近10w份画布，并赋予其坐标，zjuer们可以通过随即或指定坐标选取自己的画布并进行作画。作品版权通过区块链技术存证，<b>版权归每位作者所有</b>，所有作者将免费获得最后成品的NFT（超清作品画布与视频）。未来，你的画作可能成为你<b>在数字艺术世界的第一份认证</b>，在广袤的元宇宙中与母校永久相连。校庆期间，作品更有机会在紫金港校区进行<b>线下展览</b>，遥寄你对母校的祝愿。
      </p>
      <p><span class="red">【灿若星辰】</span>当画总数作数累积到一定数字，平台会点亮一盏象征着星辰的求是灯，参与画作越多，求是灯会越明亮，当画作总数达到12500幅，求是灯会全部点亮，触发活动隐藏彩蛋。
      </p>
      <p><span class="red">【校庆彩蛋】</span>部分作画序号下设置了彩蛋格，踩中该序号的幸运儿会收到相应的神秘奖品。</p>
      <p><span class="red">【求是园互动】</span>我们在求是园中准备了一系列线下活动，到场参与互动，点亮星辰即可获得专属点亮小礼物！</p>
      <p class="center" style="text-indent: 0">5.13-5.14 Light up · 点亮星辰<br>（11:00-13:00&16:30-18:30 文化广场）</p>
      <p><img src="https://hz-mayi.nos-eastchina1.126.net/2022-5-9/3.png"></p>
      <p class="center" style="text-indent: 0">5.15 草坪绘心<br>
        （14:30-17:00 启真湖畔，西教情人坡）</p>
      <p><img src="https://hz-mayi.nos-eastchina1.126.net/2022-5-9/4.png"></p>
      <p class="center" style="text-indent: 0">5.20 我为浙大画校庆<br>
        现场作画，共创未来，获得专属摸鱼锦囊！</p>
      <p><img src="https://hz-mayi.nos-eastchina1.126.net/2022-5-9/1.png"></p>
      <p class="center" style="text-indent: 0">5.18-5.20 摸鱼锦囊<br>
        （每天11:00-13:00 东教麦斯威食堂对面 文化长廊10号玻璃房）<br>
        凭借画作即可兑换摸鱼锦囊！</p>
      <p><img src="https://hz-mayi.nos-eastchina1.126.net/2022-5-9/2.png"></p>
      <p><span class="red">【万元大奖】</span>创作过程中zjuer们还可以通过分享作品海报、发送弹幕的方式获得点赞数，赢取万元大奖</p>
      <p><span class="bule">最佳作品奖1名</span> ：点赞数最高的作品，奖品：魔兽TROMOXMino电动摩托车*1（价值¥9999）
      <p><span class="bule">最佳人气奖1名</span>：个人所有画作点赞数总和最高，奖品：杭州四季酒店·金沙厅主厨双人晚宴*1（黑珍珠三钻，大陆第一宴会厅，价值1250*2人）</p>
      <p><span class="bule">人气作品奖10名</span>：点赞数排行2-11的作品，奖品：得力文具颐和园联名日月澄晖笔记本礼盒（价值¥88）</p>
      <p>此次“万人添画@浙江大学”活动，在<b>浙江大学创新创业学院</b>指导下，<b>由浙江大学彩虹人生思政育人中心、浙江大学在校生创业团队3Q Lab、浙江大学求是强鹰俱乐部</b>联合推出，更受到广大校友的支持，在此特别鸣谢<span
          class="red"> 浙江大学管理学院发展与联络中心、校友企业杭州摩兽出行科技有限公司、得力集团有限公司、浙大新宇集团</span>。</p>
      <p><span class="red">关于 求是强鹰：</span></p>
      <p>
        浙江大学学生求是强鹰俱乐部由浙江大学创新创业学院指导，依托坚持15年的“求是强鹰实践成长计划”，以“推动青年创新创业，引领青年正能量”为出发点，结合新时代对创新创业人才的呼唤和需求，着力提升青年大学生勇于求索、明辨是非、自强不息、敏锐似鹰的精神，培养具有全球视野与家国情怀、践行求是精神、承担社会责任的青年力量。</p>
      <p><span class="red">关于 3Qlab：</span></p>
      <p>诞生于2019年7月3日的浙江大学紫金港校区的北街咖啡。是由科学、艺术和技术领域专业人士组成的DAC（Decentralized Autonomous
        Community，去中心化自治共同体），我们立足浙大，放眼全球，聚焦跨学科跨领域协作，基于区块链技术，以金融、科学与艺术三驾齐驱，探索未来的无限可能，拓展人类的认知边界，期待更多小伙伴加入我们！</p>
      <p style="text-indent: 0">    <span class="bule">联系人：陈俊茹 15325882227</span></p>

    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.introduce {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  overflow-y: scroll;
  font-size: 14px;
  line-height: 35px;

  .data {
    width: 90%;
    margin: 0 auto;
    p{
      text-indent: 2rem;
    }

    .center {
      text-align: center;
    }

    .red {
      color: #c00000;
      font-weight: bold;
    }

    .bule {
      font-weight: bold;
      color: #0000ff;
    }

    img {
      display: block;
      width: 200px;
      margin: 0 auto;
    }
  }
}
</style>