<template>
  <div class="lamp">
    <div class="bg" :style="heights">
      <img  src="https://hz-mayi.nos-eastchina1.126.net/StarsBg/home.gif"/>
    </div>
    <div class="title">参与作画，点亮星辰</div>
    <div class="next" @click="next">进入</div>
    <div class="upper" @click="upper">上一页</div>
  </div>
</template>

<script>
import {init} from '../api/lamp'

export default {
  mounted() {
    let width = document.documentElement.clientWidth
    let height = document.documentElement.clientHeight

    let heights = 'height:' + (height /1.25) + 'px;width:100%;'
    this.heights = heights
    this.width = width
    this.height = height
    // this.Uptop()
    this.fachdata()
  },
  data() {
    return {
      bottom:false,
      start: false,
      heights: '',
      width: 0,
      height: 0,
      floor: 0,
      data: '',
      creationNumber: 0,
      LampData: 0,
      Max: 0,
      LightNextNumber: 0
    }
  },
  methods: {
    next(){
      this.$parent.upperLogin()
    },
    upper(){
      this.$parent.upperBird()
    },
    initUp() {
      this.start = true
    },
    NoinitUp(){
      this.start = false
    },
    goto() {
      this.$router.push('/zju/login')
    },
    fachdata() {
      init().then(res => {
        this.creationNumber = res.creationNumber
        this.LampData = res.LampData
        this.Max = res.Max
        this.LightNextNumber = res.LightNextNumber
        // this.Uptop()
      })
    },
    Uptop() {
      let canvas = this.$refs.canvas
      //定义长高
      canvas.width = this.width
      canvas.height = this.height
      let crx = canvas.getContext('2d')
      //填充背景白色颜色
      // crx.fillStyle = '#000'
      // crx.fillRect(0, 0, this.width, this.height);

      // let bg = new Image()
      // bg.src = 'https://hz-mayi.nos-eastchina1.126.net/StarsBg/bg.png'
      // bg.onload = (() => {
      //   crx.drawImage(bg, 0, 0, this.width, this.height);
      // })

      setTimeout(() => {
        let number = 104

        for (let i = 1; i <= number; i++) {
          let s = i
          s = this.getNunber(s)
          let url = `https://hz-mayi.nos-eastchina1.126.net/StarsBg/wrtw-${s}.png`
          let img = new Image()
          img.src = url
          img.onload = (() => {
            crx.drawImage(img, 0, 0, this.width, this.height);
          })
        }
        this.bottom=true
      }, 500)
    },
    getNunber(str) {
      if (str < 10) {
        return '00' + str
      } else if (str < 100) {
        return '0' + str
      } else if (str == 100) {
        return str
      } else {
        return str
      }

    },
  }

}
</script>

<style scoped lang="scss">
.lamp {
  width: 100%;
  height: 100%;
  background-color: #000129;
  position: relative;
  img{
    width: 300%;
    height: 100%;
    position: absolute;
    left: -100%;
  }
  video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #000129;
  }

  @mixin bgSin {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;

  }

  .title {
    color: #b4f7f5;
    position: absolute;
    top: 50px;
    width: 100%;
    text-align: center;
    font-size: 23px;
    font-family: 'Vonwaon';
  }

  .bg {
    width: 100%;
    //background-image: url("../assets/image/lamp/bg.jpg");
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;

    .one {
      @include bgSin;
    }

  }

  .next{
    position: absolute;
    right: 30px;
    top: 120px;
    color: #FFC37E;
    width: 80px;
    height: 30px;
    line-height: 30px;
    border: 2px solid #B6FFF3;
    border-radius: 5px;
    text-align: center;
  }
  .upper{
    position: absolute;
    left: 30px;
    bottom: 50px;
    color: #B6FFF3;
    width: 80px;
    height: 30px;
    line-height: 30px;
    border: 2px solid #B6FFF3;
    border-radius: 5px;
    text-align: center;
  }
}

@font-face {
  font-family: 'Vonwaon';
  src: url('../assets/font/Vonwaon.ttf');
  font-weight: normal;
  font-style: normal;
}


</style>